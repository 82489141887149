.cs-subnav {
    display: flex;
    list-style: none;
    justify-content: space-evenly;
    gap: 10px;
    border-bottom: 1px solid gray;
    padding-bottom: 10px;
    padding-left: 0;
    font-size: 1.3rem;
    margin-bottom: 40px;
}

.cs-subnav li {
    cursor: pointer;
    padding: 10px 20px;
    border-radius: 10px;
    transition: 200ms ease-in-out;
}

.cs-container {
    max-width: 1040px;
    margin: auto;
    margin-bottom: 40px;
    padding: 0 20px;
}

.cs-subnav li:hover {
    color: white;
    background-color: #4E8975;
}

.cslink {
    text-decoration: none;
    color: black;
}

.header {
    width: 100%;
}

.division {
    border-bottom: 3px solid gray;
    width: 200px;
    margin: 40px auto;
}

p {
    font-size: 1.3rem;
    line-height: 1.7rem;
}

.twocol {
    display: flex;
    gap: 60px;
    text-align: left;
    margin-top: 40px;
}

.twocol p {
    flex: 1;
}

.twoimg {
    max-width: 200px;
    object-fit: contain;
}

h1 {
    margin-top: 30px;
    font-size: 2.5rem;
}

.link-button {
    background-color: #4E8975;
    color: white;
    padding: 20px 50px;
    border-radius: 10px;
    text-decoration: none;
    font-size: 1.5rem;
}

table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    font-size: 1.3rem;
}

th,
td {
    border: 1px solid black;
    padding: 20px;
    text-align: left;
}

th {
    background-color: #4E8975;
    color: white;
}

.left {
    text-align: left;
}

.full-heading {
    background-color: #4E8975;
    color: white;
    text-align: left;
    padding: 20px;
}

.port {
    max-width: 250px;
}

.mission {
    background-image: url("./Mission.jpg");
    background-size: cover;
    color: white;
    padding: 20px 40px;
    margin-top: 50px;
}

.mission h2 {
    font-size: 4rem;
    margin-bottom: 20px;
}

.mission p {
    font-size: 2rem;
    line-height: 2.5rem;
}

.question {
    background-color: #4E8975;
    color: white;
    text-align: left;
    padding: 20px;
    font-size: 1.5rem;
}

.answer {
    text-align: left;
}

.cs-header {
    flex-direction: column;
}

.cs-subheading {
    margin: 0;
    font-size: 2rem;
}

.submit {
    cursor: pointer;
    padding: 10px 20px;
    font-size: 1.3rem;
    border: none;
}

.label {
    margin: 0;
    margin-top: 50px;
}

.form input[type="text"] {
    font-size: 1.5rem;
    background: none;
    border: none;
    border-bottom: 1px solid black;
    padding: 10px;
    width: 300px;
}

.two-inp {
    display: flex;
    gap: 40px;
}

.form {
    background-color: white;
    padding: 60px;
    margin-top: 40px;
    text-align: left;
    box-shadow: 5px 5px 10px rgba(188, 188, 188, 0.507);
    padding-top: 20px;
}

.form textarea {
    font-size: 1.5rem;
    padding: 20px;
    width: 80%;
    margin-top: 10px;
}

.info {
    text-align: center;
    font-size: 2rem;
}

.header-image-cs-services {
    background-image: url("./CSServices.jpg");
    background-size: cover;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
}

@media only screen and (max-width: 800px) {
    .twocol {
        flex-direction: column;
        gap: 10px;
    }

    .twoimg {
        margin: auto;
    }

    .cs-container {
        padding: 20px;
        padding-top: 0;
    }

    .cs-subnav {
        flex-direction: column;
    }

    .cs-header {
        margin-bottom: 0;
    }

    th,
    td {
        padding: 10px;
    }

    .link-button {
        padding: 10px 20px;
    }

    .rev {
        flex-direction: column-reverse;
    }

    .mission {
        padding: 20px;
    }

    h2 {
        margin-top: 10px;
        font-size: 2rem;
    }

    .two-inp {
        flex-direction: column;
    }

    .form {
        padding: 20px;
    }

    .form input[type='text'] {
        width: 90%;
    }

    .info {
        font-size: 1.1rem;
    }

    a {
        line-break: anywhere;
    }

    .header-image-cs-services {
        background-position: 50%;
    }
}