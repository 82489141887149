* {
  font-family: 'Josefin Sans', sans-serif;
  font-weight: bold;
}

.App {
  text-align: center;
  height: 100%;
  margin-top: 80px;
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.information {
  color: #5E5E5E;
  font-family: 'Josefin Sans', sans-serif;
  font-weight: bold;
}

.name {
  margin: 10px 0px 30px 0px;
  font-size: 36pt;
}

.logo {
  width: 20%;
  height: auto;
}

.title {
  width: 80%;
  height: auto;
}

.contact {
  font-size: 30pt;
  margin-bottom: 10px;
}

.title {
  margin-top: 60px;
}

.navbar {
  border-bottom: 1px solid #5E5E5E;
  padding: 5px 20%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #EBEDEC;
  z-index: 100;
}

.nav-items {
  display: flex;
}

.nav-item {
  color: #5E5E5E;
  text-decoration: none;
  margin-left: 20px;
  font-family: 'Josefin Sans', sans-serif;
  font-weight: bold;
  padding: 12px 14px 10px 14px;
  border-radius: 10px;
  transition: all ease-in-out 200ms;
  font-size: 14pt;
}

.nav-item:hover {
  color: white;
  background-color: #4E8975;
}

.header1 {
  font-size: 36pt;
  font-family: 'Josefin Sans', sans-serif;
  margin: 30px 10px;
}

.about-header {
  font-size: 32pt;
  font-family: 'Josefin Sans', sans-serif;
  margin-bottom: 30px;
  text-align: left;
}

.about-top-container {
  margin-bottom: 40px;
}

.services1 {
  font-size: 48pt;
  font-family: 'Josefin Sans', sans-serif;
  margin: 30px 10px;
  font-weight: bold;
}

.services2 {
  font-size: 48pt;
  font-family: 'Josefin Sans', sans-serif;
  margin: 30px 10px;
  font-weight: bold;
  width: 380px;
}

.contact-form {
  width: 500px;
  /*margin-right: 100px; */
}

.contact-input {
  background-color: #EBEDEC;
  width: 100%;
  margin: 10px;
  border: 0px;
  border-bottom: 1px solid #5E5E5E;
  padding: 5px;
  font-size: 20pt;
  font-family: 'Josefin Sans', sans-serif;
  font-weight: bold;
}

.contact-message {
  background-color: white;
  width: 100%;
  margin: 10px;
  resize: none;
  padding: 5px;
  border: none;
  border-radius: 5px;
  font-size: 15pt;
  font-family: 'Josefin Sans', sans-serif;
  font-weight: bold;
}

.contact-submit {
  width: 100%;
  padding: 10px;
  background-color: #4E8975;
  color: white;
  border: 0px;
  transition: all ease-in-out 200ms;
  margin: 10px;
  border-radius: 5px;
  font-size: 15pt;
  font-family: 'Josefin Sans', sans-serif;
  font-weight: bold;
  margin-bottom: 20px;
}

.contact-submit:hover {
  background-color: #306754;
  transform: scale(1.1);
}

.contact-info {
  color: #5E5E5E;
  font-family: 'Josefin Sans', sans-serif;
  font-weight: bold;
  font-size: 1.5rem;
}

.subheader1 {
  margin-bottom: 30px;
  font-family: 'Josefin Sans', sans-serif;
  font-weight: bold;
  font-size: 20pt;
}

.subheader2 {
  margin-bottom: 20px;
  font-family: 'Josefin Sans', sans-serif;
  font-weight: bold;
  font-size: 18pt;
  text-align: left;
}

.home-button {
  width: 100%;
  max-width: 400px;
  height: 50px;
  margin-top: 30px;
  background-color: #4E8975;
  color: white;
  border: 0px;
  box-shadow: 3px 3px 3px lightgrey;
  transition: all ease-in-out 200ms;
  border-radius: 5px;
  font-family: 'Josefin Sans', sans-serif;
  font-weight: bold;
  font-size: 14pt;
}

.home-button:hover {
  background-color: #306754;
  transform: scale(1.1);
}

.banner {
  background-color: white;
}

.contact-method {
  font-size: 20pt;
  font-family: 'Josefin Sans', sans-serif;
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: baseline;
  font-weight: bold;
  color: grey;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 10px;
  padding: 5px;
  text-align: left;
}

.contact-checkbox {
  transform: scale(1.5);
  margin-right: 10px;
}

.homepage {
  background-image: url('./Background2.png');
  background-size: cover;
  height: 100%;
  padding: 50px 0px;
}

.home-card {
  background-color: rgba(235, 237, 236, 0.8);
  margin: 0px 20%;
  padding: 30px;
  border-radius: 20px;
  box-shadow: 0px 0px 15px #202020;
}

.home-info {
  height: 500px;
}

.about-container {
  display: flex;
  padding: 0px 20%;
  justify-content: space-between;
  margin-bottom: 40px;
}

.learn-container {
  display: flex;
  padding: 0px 20%;
  justify-content: space-between;
  margin-bottom: 40px;
}

.services-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

.faq-container {
  margin: 0px 0 40px 0;
  transition: all ease-in-out 200ms;
}

.image1 {
  margin: 0px 20px;
  border-radius: 10px;
  width: 40%;
  object-fit: cover;
}

.image2 {
  border-radius: 10px;
  width: 40%;
  object-fit: cover;
}

.paragraph1 {
  text-align: left;
  margin-bottom: 20px;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 1.3vw;
  font-weight: bold;
  line-height: 2vw;
}

.paragraph2 {
  text-align: left;
  margin-bottom: 40px;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 1.5vw;
  font-weight: bolder;
}

.header-image {
  background-image: url("./Services3.jpg");
  background-size: cover;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}

.header-image2 {
  background-image: url("./Background8.jpg");
  background-size: cover;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}

.header-image3 {
  background-image: url("./Background10.jpg");
  background-size: cover;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}

.header-image4 {
  background-image: url("./Background7.png");
  background-size: cover;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}

.header-image5 {
  background-image: url("./Background2.jpg");
  background-size: cover;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}

.header-image6 {
  background-image: url("./Background5.jpg");
  background-size: cover;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}

.faq-container:hover {
  transform: scale(1.1);
}

.faq-card {
  background-color: #4E8975;
  width: 100%;
  text-align: center;
  border-radius: 10px;
  transition: all ease-in-out 200ms;
  border: 0px;
  min-height: 200px;
  padding: 10px;
}

.faq-card2 {
  background-color: white;
  width: 100%;
  text-align: center;
  border-radius: 10px;
  transition: all ease-in-out 200ms;
  border: 0px;
  min-height: 200px;
  padding: 10px;
}

.faq-question {
  font-size: 2rem;
  font-family: 'Josefin Sans', sans-serif;
  font-weight: bold;
  color: white;
}

.faq-answer {
  font-size: 1.3rem;
  font-family: 'Josefin Sans', sans-serif;
  font-weight: bold;
  color: #4E8975;
}

.side-navbar {
  height: 90%;
  position: fixed;
  background-color: #EFF1F0;
  z-index: 100;
  padding: 20px;
  border-right: 1px solid #5E5E5E;
  text-align: left;
  width: 18%;
  overflow-y: auto;
}

.side-items {
  padding-top: 100px;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 60px;
  padding-bottom: 100px;
}

.side-item {
  color: #5E5E5E;
  text-decoration: none;
  font-family: 'Josefin Sans', sans-serif;
  font-weight: bold;
  padding: 18px 18px 14px 18px;
  border-radius: 10px;
  transition: all ease-in-out 200ms;
  font-size: 1vw;
}

.side-item:hover {
  color: white;
  background-color: #4E8975;
}

.topbar {
  border-bottom: 1px solid #5E5E5E;
  align-items: center;
  background-color: #EBEDEC;
  padding: 14px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 200;
  text-align: center;
}

.menu-button {
  border: 0px;
  transition: all ease-in-out 200ms;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 3px;
  position: fixed;
  top: 22px;
  cursor: pointer;
  background-color: #EBEDEC;
}

.topbar-info {
  font-family: 'Josefin Sans', sans-serif;
  font-weight: bold;
  font-size: 14pt;
  position: fixed;
  top: 15px;
  right: 20px;
  text-align: right;
  line-height: 1.3rem;
}

.home-container {
  display: flex;
  height: 100%;
  margin-top: -80px;
}

.home-nav {
  width: 50%;
  height: 100%;
  position: fixed;
  padding: 10px;
  background-color: #e9e9e9;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-pic {
  width: 50%;
  background-color: #EFF1F0;
  height: 100%;
  position: fixed;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-items {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 5px;
  height: 75%;
}

.home-item {
  color: #5E5E5E;
  text-decoration: none;
  font-family: 'Josefin Sans', sans-serif;
  font-weight: bold;
  padding: 18px 18px 14px 18px;
  border-radius: 10px;
  transition: all ease-in-out 200ms;
  font-size: 2.5vw;
}

.home-item:hover {
  color: white;
  background-color: #4E8975;
}

.home-img {
  border-radius: 20px;
  position: fixed;
  height: 65%;
}

.home-title {
  display: flex;
  align-items: flex-start;
  position: fixed;
  top: 1%;
  z-index: 1000;
  height: 13%;
  padding-right: 1%;
}

.home-words {
  position: fixed;
  top: 14%;
  z-index: 1000;
  height: 10%;
  font-family: 'Josefin Sans', sans-serif;
  font-weight: bold;
  font-size: 2.5vh;
  display: flex;
}

.title-cont {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.top-logo {
  height: 70px;
}

.top-title {
  height: 60px;
}

.view-mob {
  font-size: 0pt;
  font-family: 'Josefin Sans', sans-serif;
  font-weight: bold;
  color: grey;
}

.view-not-mob {
  font-size: 12pt;
  font-family: 'Josefin Sans', sans-serif;
  font-weight: bold;
  color: grey;
}

.contact-reply {
  font-size: 20pt;
  font-family: 'Josefin Sans', sans-serif;
  width: 100%;
  max-width: 40%;
  margin: auto;
  font-weight: bold;
  color: grey;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: left;
}

.footer {
  padding: 20px;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 1vw;
  border-top: 1px solid lightgrey;
  color: gray;
}

.contact-me {
  padding: 30px 5px;
  font-size: 2vw;
  max-width: 100%;
  float: left;
  margin: 0;
  text-decoration: none;
}

.contact-me:hover {
  transform: scale(1.05);
}

.booked {
  margin-top: 20px;
}

.map {
  height: 400px;
  width: 500px;
}

.contact-container {
  display: flex;
  justify-content: space-around;
  gap: 40px;
}

.contact-info-container {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: left;
}

.subnav {
  background-color: rgba(0, 0, 0, 0.096);
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 30px 0;
  margin-top: 10px;
}

.flex-center {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
}

.side-container {
  cursor: pointer;
}

.profile-pic {
  height: 500px;
  border-radius: 10px;
}

.profile-container {
  margin: 0 18%;
  font-family: 'Josefin Sans', sans-serif;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.profile-section {
  display: flex;
  gap: 60px;
  align-items: center;
  background-color: #4E8975;
  width: 100%;
  color: #EBEDEC;
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
}

.profile-header {
  font-size: 3rem;
  font-family: 'Josefin Sans', sans-serif;
  font-weight: bold;
  margin-bottom: 20px;
}

.profile-overview-section {
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 1.5rem;
}

.profile-overview {
  font-size: 2rem;
  font-weight: bold;
}

.overview-list {
  margin-top: 10px;
  font-size: 1.7rem;
}

.profile-paragraph {
  font-size: 1.5rem;
  text-align: left;
}

.services-button {
  width: 100%;
}

.profile-bio {
  display: flex;
  gap: 40px;
  width: 100%;
}

.last-list {
  margin-bottom: 0;
}

.checkbox-title {
  text-align: left;
}

.subnav-container {
  text-decoration: none;
}

.expander {
  transition: 200ms ease-in-out;
}

.expander:hover {
  transform: scale(1.3);
}

.therapist-link {
  padding: 20px 40px;
  background-color: #4E8975;
  color: white;
  text-decoration: none;
  font-size: 2rem;
  transition: 200ms ease-in-out;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.therapist-link:hover {
  filter: brightness(1.3);
}

.therapist-links {
  display: flex;
  gap: 20px;
  justify-content: center;
  padding-bottom: 40px;
}

.instructions {
  font-size: 2rem;
  margin: 40px 0;
}

.about-paragraph {
  font-size: 1.5rem;
}

.instagram {
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: flex-end;
  margin-top: 5px;
}

.t-stats {
  display: flex;
  gap: 40px;
  font-size: 1.3rem;
  margin-top: 40px;
}

.t-stats div {
  flex-grow: 1;
}

.t-stats li {
  text-align: left;
  line-height: 1.5rem;
}

.t-stats p {
  font-size: 1.5rem;
  border-bottom: 1px solid grey;
  padding-bottom: 10px;
}

.t-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.t-page p {
  color: rgb(238, 238, 238);
}

.new-paragraph {
  font-size: 1.3rem;
  line-height: 1.5rem;
}

.link-button a {
  line-break: normal;
  color: white;
  text-decoration: none;
}

@media only screen and (max-width: 1200px) {
  .profile-container {
    margin: 0 10%;
  }
}

@media only screen and (max-width: 1000px) {
  .home-img {
    max-width: 45%;
    height: auto;
    max-height: 65%;
  }

  .contact-me {
    max-width: 100%;
  }

  .footer {
    font-size: 2vw;
  }

  .topbar-info {
    font-size: 10pt;
  }

  .about-container {
    padding: 0px 5%;
  }

  .about-top-container {
    padding: 0px 5%;
  }

  .services-container {
    padding: 0px 5%;
  }

  .side-navbar {
    width: 50%;
  }

  .side-item {
    font-size: 2vw;
  }

  .paragraph1 {
    font-size: 2vw;
    line-height: 3vw;
  }

  .paragraph2 {
    font-size: 2.5vw;
  }

  .contact-input {
    max-width: 80%;
  }

  .contact-form {
    width: 100%;
  }

  .contact-message {
    max-width: 80%;
  }

  .contact-submit {
    max-width: 80%;
  }

  .contact-reply {
    max-width: 80%;
    font-size: 20pt;
  }

  .contact-method {
    max-width: 80%;
    font-size: 20pt;
    flex-direction: column;
    align-items: baseline;
  }

  .bold-text {
    font-weight: 900;
  }

  .faq-container {
    margin: 0px 0 40px 0;
  }

  .contact-container {
    flex-direction: column;
  }

  .contact-method {
    padding-left: 40px;
  }

  .map {
    width: 100%;
  }

  .instructions {
    font-size: 1.5rem;
  }

  .profile-container {
    margin: 0 5%;
  }

  .profile-section {
    flex-direction: column;
  }

  .profile-pic {
    width: 300px;
    object-fit: contain;
    height: auto;
  }

  .desktop {
    display: none;
  }
}

@media only screen and (max-width: 700px) {
  .home-nav {
    width: 100%;
    padding: 0;
    padding-top: 10%;
    background-image: url("./Background7.jpg");
    background-size: cover;
  }

  .contact-me {
    max-width: 100%;
    font-size: clamp(1.5rem, 4vw, 3rem);
    box-sizing: border-box;
  }

  .footer {
    font-size: 3vw;
  }

  .home-pic {
    width: 0%;
  }

  .home-img {
    height: 0;
  }

  .home-item {
    font-size: 5vw;
  }

  .home-items {
    height: 60%
  }

  .topbar-info {
    font-size: 0;
    right: 10px;
  }

  .view-mob {
    font-size: 12pt;
  }

  .side-navbar {
    width: 100%;
    padding: 0;
    padding-top: 10%;
  }

  .side-item {
    font-size: 1rem;
  }

  .side-items {
    justify-content: space-evenly;
    gap: 0;
    grid-gap: 0;
    height: 80%;
    padding-top: 0;
  }

  .about-container {
    flex-direction: column;
    padding: 0px 5%;
  }

  .services-container {
    flex-direction: column;
    padding: 0px 5%;
  }

  .paragraph1 {
    font-size: 3.5vw;
    line-height: 5vw;
  }

  .paragraph2 {
    font-size: 4.5vw;
  }

  .image1 {
    width: 100%;
    margin: 40px 0;
    margin-top: -30px;
  }

  .top-logo {
    height: 50px;
  }

  .top-title {
    height: 40px;
  }

  .App {
    margin-top: 50px;
  }

  .menu-button {
    top: 16px;
  }

  .home-title {
    height: 10%;
    top: 3%;
  }

  .home-logo-1 {
    width: 0;
  }

  .home-words {
    flex-direction: column;
  }

  .dis-mob {
    width: 0;
  }

  .serv-img {
    margin-top: 15px;
    margin-bottom: 0px;
  }

  .faq-container {
    margin: 0px 0 40px 0;
  }

  .view-not-mob {
    font-size: 0pt;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: bold;
    color: grey;
  }

  .faq-container:hover {
    transform: scale(1);
  }

  .contact-container {
    margin: 10px;
  }

  .map {
    width: 100%;
  }

  .instructions {
    font-size: 1.3rem;
  }

  .therapist-links {
    flex-direction: column;
  }

  .profile-container {
    margin: 0 10px;
  }

  .services1 {
    font-size: 36pt;
  }

  .header-image {
    background-position: 100%;
  }

  .t-page {
    flex-direction: column;
    text-align: center !important;
  }

  .t-stats {
    flex-direction: column;
    gap: 10px;
  }
}